<template>
  <div>
    <b-container class="base-container-x pt-2" style="min-height:400px;background-color:#fff">
      <b-row class="mb-5 justify-content-center">
        <b-col cols="12">
          <StepFormRegister :currentStep="1" :titleListSteps="this.titleListSteps"/>
        </b-col>
        <b-col xl="9" lg="9" sm="12" md="12" class="border rounded py-4 py-md-5 border-light-gray">
          <h3 class="content-header-title text-primary-dark">{{ $t('School information') }}</h3>
          <div class="mt-4">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
              <validation-provider
                :name="$t('School name')"
                rules="required"
                autocomplete="off"
                v-slot="{errors}"
              >
              <b-form-group>
                <label for="school_name" class="text-primary-dark font-weight-600">{{ $t('School name') }} <span class="text-danger-light">*</span></label>
                <b-form-input
                  id="school_name"
                  type="text"
                  v-model="form.school_name"
                  :placeholder="$t('Enter school name')"
                >
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
              </validation-provider>
              <validation-provider
                :name="$t('Address')"
                rules="required"
                autocomplete="off"
                v-slot="{errors}"
              >
              <b-form-group>
                <label for="address" class="text-primary-dark font-weight-600">{{ $t('Address') }} <span class="text-danger-light">*</span></label>
                <b-form-input
                  id="address"
                  v-model="form.school_address"
                  :placeholder="$t('Enter Address')"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
              </validation-provider>
              <validation-provider
                :name="$t('Location')"
                rules="required"
                autocomplete="off"
                v-slot="{errors}"
              >
              <b-form-group>
                <label for="location" class="text-primary-dark font-weight-600">{{ $t('Location') }} <span class="text-danger-light">*</span></label>
                <v-select id="location" class="form-v-selects" :placeholder="$t('Province or States')" label="text" :filterable="false" :options="regions" v-model="selectedRegion" @search="searchRegion">
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search 11"
                      v-bind="attributes"
                      v-on="events"
                      @click="setReadOnly"
                    />
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      {{ $t('No results found for') }} <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>{{ $t('Typing to search for a Province or States') }}</em>
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.text }}
                      </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.text }}
                    </div>
                  </template>
                </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
              </validation-provider>
              <b-row>
                <b-col sm="12" lg="6">
                  <validation-provider
                    :name="$t('Email')"
                    rules="required|email"
                    autocomplete="off"
                    v-slot="{errors}"
                  >
                  <b-form-group>
                    <label for="email" class="text-primary-dark font-weight-600">{{ $t('Email') }} <span class="text-danger-light">*</span></label>
                    <b-form-input
                      id="email"
                      v-model="form.school_email"
                      :placeholder="$t('Enter Email')"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col sm="12" lg="6">
                  <validation-provider
                    :name="$t('Phone number')"
                    rules="required"
                    autocomplete="off"
                    v-slot="{errors}"
                  >
                    <b-form-group>
                      <label for="phone" class="text-primary-dark font-weight-600">{{ $t('Phone number') }} <span class="text-danger-light">*</span></label>
                      <b-form-input
                        id="phone"
                        rules="required"
                        v-model="form.school_phone_number"
                        :placeholder="$t('Enter Phone number')"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <h3 class="content-header-title text-primary-dark mt-4">{{ $t('Social media') }}</h3>
              <b-row>
                <b-form-group class="col-lg-6">
                  <label for="website" class="text-primary-dark font-weight-600">{{ $t('Website') }}</label>
                  <b-form-input
                    id="website"
                    v-model="form.website_url"
                    :placeholder="$t('Paste your link here')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-lg-6">
                  <label for="facebook_URL" class="text-primary-dark font-weight-600">{{ $t('Facebook URL') }}</label>
                  <b-form-input
                    id="facebook_URL"
                    v-model="form.facebook_url"
                    :placeholder="$t('Paste your link here')"
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group class="col-lg-6">
                  <label for="twitter_URL" class="text-primary-dark font-weight-600">{{ $t('Twitter URL') }}</label>
                  <b-form-input
                    id="twitter_URL"
                    v-model="form.twitter_url"
                    :placeholder="$t('Paste your link here')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-lg-6">
                  <label for="instagram_URL" class="text-primary-dark font-weight-600">{{ $t('Instagram URL') }}</label>
                  <b-form-input
                    id="instagram_URL"
                    v-model="form.instagram_url"
                    :placeholder="$t('Paste your link here')"
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-row>
                <b-col lg="6" sm="12">
                  <h3 class="content-header-title text-primary-dark mt-4">{{ $t('Owner') }}</h3>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group class="mt-4">
                    <b-form-checkbox-group>
                      <b-form-checkbox
                        id="use_user_data"
                        v-model="useUserData"
                        value="1"
                      >{{ $t('Enter information from your profile.') }}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <validation-provider
                :name="$t('Primary contact name')"
                rules="required"
                autocomplete="off"
                v-slot="{errors}"
              >
              <b-form-group>
                <label for="contact_name" class="text-primary-dark font-weight-600">{{ $t('Primary contact name') }} <span class="text-danger-light">*</span></label>
                <b-form-input
                  id="contact_name"
                  type="text"
                  v-model="form.owner_contact_name"
                  :placeholder="$t('Enter contact name')"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
              </validation-provider>
              <validation-provider
                :name="$t('Primary contact name')"
                rules="required"
                autocomplete="off"
                v-slot="{errors}"
              >
              <b-form-group>
                <label for="contact_email" class="text-primary-dark font-weight-600">{{ $t('Email') }} <span class="text-danger-light">*</span></label>
                <b-form-input
                  id="contact_email"
                  type="email"
                  rules="required|email"
                  v-model="form.owner_email"
                  :placeholder="$t('Enter contact email')"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
              </validation-provider>
              <validation-provider
                :name="$t('Phone number')"
                rules="required"
                autocomplete="off"
                v-slot="{errors}"
              >
              <b-form-group>
                <label for="contact_phone_number" class="text-primary-dark font-weight-600">{{ $t('Phone number') }} <span class="text-danger-light">*</span></label>
                <b-form-input
                  id="contact_phone_number"
                  type="text"
                  v-model="form.owner_phone_number"
                  :placeholder="$t('Enter contact phone number')"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
              </validation-provider>
              <b-row class="justify-content-center mt-4">
                <b-button type="reset" @click="onPrevious" pill class="btn-default text-primary-dark font-weight-bold px-5">{{ $t('Previous') }}</b-button>
                <b-button type="submit" pill :disabled="isLoading" class="btn-primary-dark ml-3 font-weight-bold px-5"><b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."/>{{ $t('Next') }}</b-button>
              </b-row>
            </b-form>
            </ValidationObserver>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import StepFormRegister from '../../components/form/StepFormRegister'
import Api from '../../api/api'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'SchoolRegisterInformation',
  title: ' | School register information',
  components: {
    StepFormRegister
  },
  data () {
    return {
      currentStep: 1,
      titleListSteps: [this.$t('Start'), this.$t('School info'), this.$t('Yoga info'), this.$t('Document'), this.$t('Payment'), this.$t('Finished')],
      form: {
        id: null,
        user: null,
        school_name: '',
        school_address: '',
        regions: null,
        school_email: '',
        school_phone_number: '',
        website_url: '',
        facebook_url: '',
        twitter_url: '',
        instagram_url: '',
        owner_contact_name: '',
        owner_email: '',
        status: 1,
        owner_phone_number: ''
      },
      useUserData: [],
      tempSchool: {},
      selectedRegion: null,
      regions: [],
      isLoading: false
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    await this.$store.dispatch('userProfile')
    this.form.user = this.userProfile.id
    this.getSchool()
  },
  watch: {
    useUserData () {
      this.useDataOwnerFromUserProfile()
    }
  },
  methods: {
    setReadOnly (el) {
      setTimeout(() => {
        el.target.removeAttribute('readonly')
      }, 80)
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    onSubmit () {
      this.$refs.form.validate().then(result => {
        if (result) {
          this.form.region = this.selectedRegion.value
          if (this.form.id === null || this.form.id === undefined) {
            this.createSchool()
          } else {
            this.updateSchool()
          }
        }
      })
    },
    createSchool () {
      this.isLoading = true
      Api.createSchool(this.form)
        .then((response) => {
          this.isLoading = false
          localStorage.setItem('schoolId', response.data.id)
          this.$router.push({ name: 'schoolRegisterYogaAndFaculty' })
        })
        .catch((error) => {
          this.isLoading = false
          const responseError = error.response
          let textError = ''
          if (responseError) {
            textError = Object.values(responseError.data).join()
          } else {
            textError = this.$t('Unsuccessfully register yoga school')
          }
          this.$notify({
            group: 'error',
            title: this.$t('Register yoga school'),
            text: textError
          })
        })
    },
    updateSchool () {
      this.isLoading = true
      Api.updateSchool(this.form)
        .then((response) => {
          this.isLoading = false
          this.$router.push({ name: 'schoolRegisterYogaAndFaculty' })
        })
        .catch((error) => {
          this.isLoading = false
          const responseError = error.response
          let textError = ''
          if (responseError) {
            textError = Object.values(responseError.data).join()
          } else {
            textError = this.$t('Unsuccessfully register yoga school')
          }
          this.$notify({
            group: 'error',
            title: this.$t('Register yoga school'),
            text: textError
          })
        })
    },
    useDataOwnerFromUserProfile () {
      if (this.useUserData.length) {
        this.form.owner_contact_name = this.userProfile.firstName + ' ' + (this.userProfile.lastName || '')
        this.form.owner_email = this.userProfile.email
        this.form.owner_phone_number = this.userProfile.profile.phoneNumber
      } else {
        if (this.tempSchool) {
          this.form.owner_contact_name = this.tempSchool.owner_contact_name
          this.form.owner_email = this.tempSchool.owner_email
          this.form.owner_phone_number = this.tempSchool.owner_phone_number
        } else {
          this.form.owner_contact_name = ''
          this.form.owner_email = ''
          this.form.owner_phone_number = ''
        }
      }
    },
    onPrevious () {
      this.$router.push({ name: 'applyType' })
    },
    getSchool () {
      this.$store.dispatch('getMySchool', { user: this.userProfile.id }).then(response => {
        if (response.data.results.length > 0) {
          if (response.data.results[0].status !== 1) {
            this.$router.push({ name: 'schoolProfile' })
          }
          for (const property in this.form) {
            if (property in response.data.results[0]) {
              this.form[property] = response.data.results[0][property]
            }
            this.selectedRegion = { value: response.data.results[0].region, text: response.data.results[0].region_name }
          }
          this.tempSchool = response.data.results[0]
        }
      })
    },
    searchRegion (searchText, loading) {
      this.searchText = searchText
      if (this.searchText) {
        this.regions = []
        loading(true)
        Api.searchRegion({ q: this.searchText }).then(response => {
          if (response.data.results) {
            response.data.results.forEach(region => {
              const url = region.url.split('/')
              this.regions.push({ value: url[url.length - 2], text: region.display_name })
            })
          }
          loading(false)
        }).catch(() => {
          loading(false)
        })
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style >
  .v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
  }

  .v-select .dropdown li:last-child {
    border-bottom: none;
  }

  .v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
  }

  .v-select .dropdown-menu .active > a {
    color: #fff;
  }
  .vs__dropdown-menu > li {
    padding: 5px !important;
    padding-left: 7px !important;
    border-bottom: 1px solid #f6f8fa;
  }
</style>
